<template>
  <div>
    <div class="uk-section">
      <div class="uk-container uk-container-large">
         <vue-headful :title="category.name" />
        <h1><v-icon  color="purple darken-2" large left    >mdi-dialpad</v-icon>{{ category.name }}</h1>

        <ArticlesList :articles="category.articles || []"></ArticlesList>
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesList from "../components/ArticlesList";
import gql from "graphql-tag";

export default {
 // title: 'Category : ', //+ this.category.name,
  data() {
    return {
      category: [],
      routeParam: this.$route.params.id
    };
  },
  components: {
    ArticlesList
  },
  apollo: {
    category: {
      query: gql`
        query Category($id: ID!) {
          category(id: $id) {
            name
            articles (where: { is_publish: true} ) {
              id
              title
              content
              is_publish
              premium_category
              image {
                url
              }
              category {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.routeParam };
      }
    }
  }
};
</script>
