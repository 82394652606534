<template>
   <div>
        

      <v-navigation-drawer v-model="sidebar"  app >
        <v-list dense nav>
          
          <v-list-item
            v-for="category in categories"
            :key="category.id"
            :to="{ path: '/category/' + category.id }">  
                  <v-list-item-icon>
                    <v-icon></v-icon>
                  </v-list-item-icon>         
                
                  <v-list-item-content>
                  <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item-content>                     
          </v-list-item>
        
        </v-list>
        
      </v-navigation-drawer>
  
  
      <v-app-bar  
        app 
         color=""
      
      >
        <span class="hidden-sm-and-up">
          <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
        </span>
        <v-toolbar-title>
         
          <router-link to="/" tag="span" style="cursor: pointer">
            <v-icon  color="purple darken-2" large left >widgets</v-icon>Supersimplearn
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only">
          <v-btn transparent  
            text
        
            v-for="category in notall"  
            :key="category.id"
       
            :to="{ path: '/category/' + category.id}"
            >
            <v-icon  left dark>mdi-dialpad</v-icon>
            {{  category.name }}
          </v-btn>

           
        </v-toolbar-items>
      </v-app-bar>
    </div>

    
    
   
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "Nav",
  data:function() {
    return {
      categories: [],
      sidebar: false,
     
    };
  },
  computed: {
  notall: function ( ) {
     
      return this.categories.filter(
         function (c){
           console.log (c.name);
           return ( c.name===c.name)

} 

      )
     
  }


   
},
 
  apollo: {
    categories: gql`
      query Categories {
        categories   {
          id 
          name
        }
      }
    `
  }
};
</script>
