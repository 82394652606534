<template>
  <div>
    <div
      v-if="article.image"
      id="banner"
      class="uk-height-small uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding"
      :data-src="api_url + article.image.url"
      uk-img
    >
      <vue-headful :title="article.title" />
      <h1><v-icon  color="purple darken-2"   x-large left >widgets</v-icon>{{ article.title }} </h1>
    </div>

    <div class="uk-section">
      <div class="uk-container uk-container-small">
        <!--vue-markdown-it
          v-if="article.content"
          :source="article.content"
          id="editor"
        /-->

        <markdown-it-vue 
         v-if="article.content"
        class="md-body" :content="article.content" :options="options" id="editor" />
        <p v-if="article.published_at">
          published on: {{ moment(article.published_at).format(" Do MMM YYYY") }}
        </p>
        <hr>
        <div class='comments'>
          <Disqus shortname='learn-karyavirtual-com' />
        </div>
        <hr>
      </div>
      
    </div>
    
  </div>
  
</template>

<script>
var moment = require("moment");
//import VueMarkdownIt from "vue-markdown-it";
 
import MarkdownItVue from 'markdown-it-vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';

import gql from "graphql-tag";
import { Disqus } from 'vue-disqus'

 
export default {
 // title: 'Article ',// + this.article.title,
  data() {
    return {
      article: {},
      options: {
        markdownIt: {
          linkify: true
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener'
          }
        }
      },
      moment: moment,
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id
    };
  },
  components: {
    //VueMarkdownIt
    MarkdownItVue,
    Disqus
  },
  apollo: {
    article: {
      query: gql`
        query Articles($id: ID!) {
          article(id: $id) {
            id
            title
            content
            image {
              url
            }
            published_at
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam
        };
      }
    }
  }
};
</script>
