<template>
  <div>
    <div class="uk-section">
      <div class="uk-container uk-container-large">
        <h1 style="font-size:23">Supersimplearn </h1>
         <h2 style="font-size:23">Platform & portal personal development (pengembangan diri)</h2>

        <ArticlesList :articles="articles"></ArticlesList>
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesList from "../components/ArticlesList.vue";
import gql from "graphql-tag";

export default {
  //title: 'Article list Page',
  components: {
    ArticlesList
  },
  data() {
    return {
      articles: []
    };
  },
  apollo: {
    articles: gql`
      query Articles {
        articles (where:  {is_publish:true}) {
          id
          title
          content
          is_publish
          premium_category
          image {
            url
          }
          category {
            name
          }
        }
      }
    `
  }
};
</script>
